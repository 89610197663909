<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        สร้างใบสั่งซื้อสินค้า : PU2-1
      </h3>
      <div class="card-toolbar">
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel()"
        >
          นำข้อมูลเข้าจาก Excel
        </button> -->
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >เพิ่มใบสั่งซื้อ
        </button>
      </div>
    </div>
    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>#</th>
              <th>เลขที่เอกสาร</th>
              <th>รหัสผู้ขาย</th>
              <th>ชื่อผู้ขาย</th>
              <th>วันที่สร้างเอกสาร</th>
              <th>มูลค่า</th>
              <th>status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="7">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ record.no }}</td>

              <td>
                <a style="font-weight: bold" @click="goToItemDetail(item)"
                  ><u>
                    {{ item.purchase_no ? item.purchase_no : "" }}
                  </u>
                </a>
              </td>
              <td>{{ item.vendor_code ? item.vendor_code : "" }}</td>
              <td>{{ item.vendor_name ? item.vendor_name : "" }}</td>
              <td>{{ formatDate(item.createdAt.substring(0, 10)) }}</td>
              <td>{{ item.final_price }}</td>

              <!-- <td
                @click="item.status !== '1' ? handleApprove(item) : ''"
                :style="
                  item.status === '1'
                    ? 'cursor: ; color: green'
                    : item.status === '0'
                    ? 'cursor: pointer; color: blue'
                    : 'cursor: pointer; color: red'
                "
              > -->
              <td
                :style="
                  item.status === '1'
                    ? 'cursor: ; color: LightSeaGreen'
                    : item.status === '0'
                    ? 'cursor: ; color: blue'
                    : 'cursor: ; color: red'
                "
              >
                {{
                  item.status === "1"
                    ? "อนุมัติเพิ่ม รอดำเนินการ"
                    : item.status === "0"
                    ? "รออนุมัติการเพิ่ม"
                    : "ไม่อนุมัติ"
                }}
              </td>
              <td>
                <div class="row">
                  <div class="col-sm-6 d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="goToEdit(item.id)"
                    >
                      <i
                        :class="
                          item.status === '0'
                            ? 'bi bi-pencil-square'
                            : 'bi bi-eye-fill'
                        "
                      ></i>
                    </button>
                  </div>
                  <div @mousedown="hoverExportBtn()" class="col-sm-6">
                    <button
                      v-if="item.status !== '1'"
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                    <button
                      v-if="item.status === '1'"
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                      @click="goToExport(item, index)"
                    >
                      <div
                        :class="
                          !loadingExport
                            ? ''
                            : index == indexExport
                            ? 'spinner-grow spinner-grow-sm text-light'
                            : ''
                        "
                        role="status"
                      >
                        <i class="bi bi-printer-fill"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div v-if="isExport">
      <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import purchaseApi from "@/api/purchase/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import moment from "moment";

import pdfFile from "../../components/files/purchaseFile.vue";

export default {
  components: {
    DialogConfirmByPass,
    Pagination,
    Loader,
    pdfFile,
  },
  setup() {
    document.title = "CHOMTHANA | สร้างใบสั่งซื้อสินค้า";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    dialogConfirmByPass: false,
    isExport: false,
    loadingExport: false,
    indexExport: "",

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],
  }),

  created() {
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      let companyId = localStorage.getItem("companyId");
      try {
        responseData = await purchaseApi.purchaseOrder.getAllByStatus(
          "0",
          "1",
          "2"
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        const filterData = responseData.data.filter(
          (data) => data.status !== "3"
        );
        this.dataItemsShow = filterData;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = filterData;
        this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push({
        path: "/purchase/PU2-1/new",
        query: {},
      });
    },
    goToEdit(id) {
      this.$router.push({
        path: "/purchase/PU2-1/edit",
        query: {
          id: id,
        },
      });
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ เอกสารเลขที่ : ${item.purchase_no} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
          this.deleteItems(item);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await purchaseApi.purchaseOrder.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    async deleteItems(item) {
      let deleteItemsResponse = [];
      let newItems = [];

      item.purchaseOrderItems.forEach((element) => {
        newItems.push(element.id);
      });

      deleteItemsResponse = await purchaseApi.purchaseOrder.deleteItems(
        newItems
      );
      if (deleteItemsResponse.response_status === "SUCCESS") {
        // Swal.fire({
        //   icon: "success",
        //   title: "ลบสำเร็จ",
        //   showConfirmButton: false,
        //   timer: 1500,
        // }).then(() => {
        //   this.dataItemsShow.splice(index, 1);
        //   this.tableItems.splice(index, 1);
        //   this.getAll(1);
        // });
      } else {
        Swal.fire({
          icon: "error",
          title: "ลบรายการสินค้าในใบสั่งซื้อไม่สำเร็จ !",
          showConfirmButton: true,
          timer: 4000,
        });
      }
    },
    async statusApprove(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
      };
      updateResponse = await purchaseApi.purchaseOrder.update(
        id,
        this.itemApprove
      );
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "success",
            title: `อนุมัติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    textSearch(val) {
      console.log({ val });
    },

    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");

        return oldDate;
      } else {
        return "-";
      }
    },

    goToItemDetail(item) {
      this.$router.push({
        path: "/purchase/PU2-1/edit",
        query: {
          id: item.id,
          status: item.status,
        },
      });
    },

    // Export file
    goToExport(item, index) {
      this.dataExport = item;
      this.indexExport = index;
      this.loadingExport = true;
      this.isExport = true;
    },
    generatedFile() {
      this.isExport = false;
    },
    hoverExportBtn() {
      this.loadingExport = false;
      this.isExport = false;
    },

    async search() {
      this.loading = true;

      const status = ["0", "1", "2"];

      const responseSearch = await purchaseApi.purchaseOrder.search(
        {
          search: this.searchInput,
        },
        status
      );

      if (responseSearch.response_status === "SUCCESS") {

        this.dataItemsShow = responseSearch.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
